<template>
  <div class="notifications">
    <div class="introb1__close">
      <AppButtonCloseOverlay />
    </div>
    <div class="introb1__wrapper">
      <div class="introb1__content introb1__content">
        <h1 class="introb1__h1 h1 introb1__h1--nomarginbottom">
          Betriebsurlaub
          <br>
        </h1>
        <p class="p introb1__p">

          <span>
            Wir machen Urlaub
            vom 20.12.2024 - 6.1.2025
ab dem 7.01.2025 sind wir gerne wieder für Sie da.
<br>
Merry Christmas & a Happy New Year! Wünscht Ihnen Ihr
Autotechnik Gabriel Team
          </span>
        </p>
        <div class="introb1__boxbutton">
          <AppButton class="introb1__btn">Zurück zur Seite</AppButton>
        </div>
      </div>
    </div>
    <div class="introb1__logo">
      <TheLogo />
    </div>
  </div>
</template>

<script>
import TheLogo from "@/components/ui/logo/TheLogo.vue";
import AppButton from "@/components/ui/buttons/AppButton.vue";
import AppButtonCloseOverlay from "../../ui/buttons/AppButtonCloseOverlay.vue";
export default {
  components: { AppButtonCloseOverlay, TheLogo, AppButton },
};
</script>

<style lang="scss">
.notifications {
  background-color: $main-color-3;

  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.introb1__close {
  position: absolute;
  right: 5px;
  top: 10px;

  @media screen and (min-width: $medium) {
    right: 20px;
    top: 20px;
  }
}

.introb1__p {
  text-align: center;
}
</style>
